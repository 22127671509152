body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "open sans", sans-serif;
  font-size: 16px;
  background-color: #1d1d1a;
  color: #fff;
}

.accordion-button {
  background-color: #333333 !important;
  border-color: #1d1d1a !important;
  color: #fff !important;
}

.accordion-button::after {
  background-image: url("../public/assets/rtb.png") !important;
}

.accordion-button::after {
  justify-content: center;
  text-align: center;
}

.accordion-body {
  padding: 0;
}

.card {
  margin: 4px;
  background-color: #333333;
  color: #fff;
}

/* WELCOME MODAL CSS */

.modal-header>.btn-close {
  background-color: white;
}

.modal-header {
  color: black;
  background-color: #fbb033;
  border-color: #333333;
}

.modal-title {
  width: 100vw;
  font-size: 19px;
  text-align: center;
}

.modal-dialog>*,
.modal-dialog {
  background-color: #1d1d1a;
  text-align: center;
}

.modal-body > div {
  padding-top: 15%;
  padding-bottom: 25px;
}

.modal-footer {
  justify-content: center;
  border-color: #333333;
}

.modal-footer > * {
  font-size: 20px;
}

.close-welcome-msg:hover,
.close-welcome-msg::selection,
.close-welcome-msg {
  background-color: #fbb033;
  color: black;
}

.modalImg {
  box-shadow: 0px 0px 15px black;
}
/* FILTERS CSS */

.input-group > * {
  font-size: 20px;
}

.input-group {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.filterForm {
  border: 15px #1d1d1a;
}

.filterField,
.filterField:active,
.filterField:focus{
/*   background-image: url("../public/assets/arrow.svg") !important; */
  background-color: #1d1d1a;
  color: #fff;
}

.searchBarRow {
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.filterField::placeholder {
  color: #777777;
}

.filterLabel {
  background-color: #1d1d1a;
  color: #fff;
}

.form-check-input:checked {
  background-color: #fbb033;
}

/* .form-control {
  border-right: 0;
  border-radius: 0px;
} */

.card > span {
  padding-left: 5px;
  padding-right: 5px;
}